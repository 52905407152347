<template>
  <div class="development">
    <!-- <heads></heads> -->
    <div class="con_box">
      <div class="project_down">
        <p class="title"><b>课题申报文件</b></p>
        <div class="content">
          <p>
            <a
              target="_blank"
              href="https://cloudedu-img.chn5000.com.cn/projects/%E3%80%90%E8%BD%AC%E6%9B%B2%E3%80%91%E3%80%902023%E6%96%87%E5%8C%96%E8%87%AA%E4%BF%A1%E3%80%91%E5%85%A8%E5%9B%BD%E4%B8%AD%E5%B0%8F%E5%AD%A6%E4%BC%A0%E7%BB%9F%E6%96%87%E5%8C%96%E5%88%9B%E6%96%B0%E6%95%99%E8%82%B2%E5%9F%BA%E5%9C%B0%E7%94%B3%E6%8A%A5%E6%96%87%E4%BB%B6%281%29.pdf"
              >全国中小学传统文化创新教育基地申报文件</a
            >
          </p>

          <p>
            <a
              target="_blank"
              href="https://cloudedu-img.chn5000.com.cn/projects/%E3%80%90%E8%BD%AC%E6%9B%B2%E3%80%91%E9%AA%91%E9%A9%AC%E9%92%89%E3%80%90%E5%85%9A%E7%BB%84%E7%BB%87%E3%80%91%E5%85%B3%E4%BA%8E%E5%BC%80%E5%B1%95%E2%80%9C%E5%85%9A%E6%94%BF%E5%B9%B2%E9%83%A8%E6%96%87%E5%8C%96%E8%87%AA%E4%BF%A1%E5%AD%A6%E4%B9%A0%E7%A4%BA%E8%8C%83%E5%85%9A%E7%BB%84%E7%BB%87%E2%80%9D%E6%8E%A8%E9%80%89%E5%B7%A5%E4%BD%9C%E7%9A%84%E9%80%9A%E7%9F%A5.pdf"
              >关于开展“党政干部文化自信学习示范党组织”推选工作的通知</a
            >
          </p>

          <p>
            <a
              target="_blank"
              href="https://cloudedu-img.chn5000.com.cn/projects/%E5%BE%81%E5%8F%AC%E5%B9%BC%E5%84%BF%E5%9B%AD%E4%BC%A0%E7%BB%9F%E6%96%87%E5%8C%96%E7%A4%BA%E8%8C%83%E5%9B%AD0406.pdf"
              >征召幼儿园传统文化示范园</a
            >
          </p>
        </div>
      </div>
    </div>
    <!-- <foot></foot> -->
  </div>
</template>
<script>
import "@css/ico.css";
import "@css/foot.css";
import heads from "@components/head.vue";
import foot from "@components/foot.vue";

export default {
  components: {
    heads,
    foot,
  },
  props: {},
  data() {
    return {
      backgroud: "",
      pathUrl: "",
    };
  },

  created: function () {
    document.title = this.$route.meta.title;
    this.pathUrl = this.$route.meta.path;
    this.backgroud = this.$route.meta.background;
  },
};
</script>

<style>
body {
  background: #f2f2ff;
}
.development {
  background: #f2f2ff;
  height: 100%;
}
.development > .con_box {
  width: 100%;
  height: 100%;
  padding-top: 30px;
}
.project_down .title {
  text-align: center;
  font-family: HYQuanTangShiJ;
  font-size: 60px;
}

.project_down .content {
  font-size: 40px;
  padding-left: 22px;
}

.project_down .content p {
  line-height: 40px;
}
</style>